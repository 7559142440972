import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

// Components
import { Link } from "gatsby";
import ItemTable from "../components/ItemTable";

const Breadcrumbs = styled.nav`
  ol {
    li {
      a {
        text-transform: capitalize;
      }
    }
  }
`;

export default class itemPage extends React.Component {
  render() {
    const { pageContext } = this.props;
    const {
      gear,
      type,
      subStatsColumns,
      pageTitle,
      pageDescription,
    } = pageContext;

    let appendix = type;

    if (type === "main") {
      appendix = `Main Weapon`;
    } else if (type === "sub") {
      appendix = `Sub-Weapon`;
    }

    return (
      <>
        <Helmet title={pageTitle} description={pageDescription}>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
          />

          <meta name="description" content={pageDescription} />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta
            property="og:url"
            content={`https://bdm.wiki/items/${gear[0].type}`}
          />
          <meta
            property="og:image"
            content="https://bdm.wiki/BDMwiki-logo.jpg"
          />
          <meta property="og:type" content="”website”" />
        </Helmet>

        <div className="container mt-3 mb-5">
          <div className="row">
            <div className="col">
              <Breadcrumbs aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {appendix}
                  </li>
                </ol>
              </Breadcrumbs>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <ItemTable gear={gear} subStatsColumns={subStatsColumns} />
            </div>
          </div>

          <div className="row mt-4 d-block d-md-none">
            <div className="col">
              <Breadcrumbs aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/`}>Items</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {appendix}
                  </li>
                </ol>
              </Breadcrumbs>
            </div>
          </div>
        </div>
      </>
    );
  }
}
