import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

// Utils
import { getItemSlug, StatNames } from "../utils/item";

// Components
import { Link } from "gatsby";
import ItemIcon from "../components/item/ItemIcon";

const Icon = styled.td`
  position: relative;
  padding: 5px !important;
  width: 60px !important;
`;

const ItemRow = styled.tr`
  cursor: pointer;

  td {
    text-align: right;
    text-transform: capitalize;

    &:first-child,
    &:nth-child(2) {
      text-align: left;
    }

    &:nth-child(2) {
      min-width: 140px;
    }

    @media (max-width: 576px) {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  &:hover,
  &:focus {
    td {
      background-color: #333;
      color: #fff;
      border-color: #999;

      a {
        color: #fff;
      }
    }
  }
`;

const TableCard = styled.div`
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const StatTD = styled.td`
  ${p =>
    p.negative
      ? `
      color: red;
    `
      : `
      color: green;
      
      &::before {
        content: "+";
      }
    `};
`;

export default class ItemTable extends React.Component {
  render() {
    const { gear, subStatsColumns } = this.props;

    if (!gear || !subStatsColumns) return null;

    return (
      <TableCard
        className="card"
        style={{
          overflow: "hidden",
          overflowX: "scroll",
        }}
      >
        <table className="table table-bordered table-striped m-0">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">AP</th>
              <th scope="col">DP</th>
              {subStatsColumns &&
                subStatsColumns.map((stat, i) => {
                  return (
                    <th scope="col" key={i}>
                      {StatNames[stat]}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {gear.map((item, i) => {
              const slug = getItemSlug(item.type, item.name);
              return (
                <ItemRow onClick={() => navigate(slug)} key={i}>
                  <Icon scope="row" color={item.rarity}>
                    <ItemIcon image={(item.images && item.images[0]) || null} />
                  </Icon>
                  <td>
                    <Link to={slug}>{item.name}</Link>
                  </td>
                  <td>{item.baseAp}</td>
                  <td>{item.baseDp}</td>
                  {subStatsColumns &&
                    subStatsColumns.map((stat, i) => {
                      if (item.subStats && item.subStats.length > 0) {
                        const filteredSubStats = item.subStats.filter(
                          y => y.name === stat,
                        );

                        if (!filteredSubStats || filteredSubStats.length < 1) {
                          return <td key={i}>-</td>;
                        }
                        return (
                          <StatTD
                            key={i}
                            negative={filteredSubStats[0].amount < 0}
                          >
                            {filteredSubStats[0].amount}
                            {filteredSubStats[0].type === "percent" ? "%" : ""}
                          </StatTD>
                        );
                      }
                      return <td key={i}>-</td>;
                    })}
                </ItemRow>
              );
            })}
          </tbody>
        </table>
      </TableCard>
    );
  }
}
